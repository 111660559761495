export const options = {
  title:
    "Listele cu persoanele selectate în grupul țintă al proiectului – septembrie 2023",
  category: "EVALUARE",
  category_link: "/projectsevaluation",
  id: 85,
};

export default function Post() {
  return (
    <>
      <div class="accordion-content mt-4">
        <p class="mb-3 w-full md:text-lg">
          A fost publicată lista cu persoanele acceptate în grupul țintă în luna
          septembrie 2023 în urma procesului de verificare și evaluare a
          dosarelor de grup țintă.
        </p>
        <p class="mb-3 w-full md:text-lg">
          Persoanele selectate au fost contactate de către experții grup țintă
          pentru semnarea contractului de membru grup țintă.
        </p>
        <a
          href="https://www.marinex-constructii.ro/proiect/154155/wp-content/uploads/sites/2/2023/10/7.-A4_sep.23_Lista-dosare-selectate-publicata.pdf"
          class="items-center text-lg underline hover:text-red-500"
        >
          A4_sep.23_Lista-dosare-selectate-publicata
        </a>
      </div>
    </>
  );
}
