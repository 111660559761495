export const options = {
  title: "UAN SOLUTIONS SRL – restaurant nou în Alba Iulia",
  category: "AFACERI",
  category_link: "/projectsbusiness",
  id: 77,
};

export default function Post() {
  return (
    <>
      <div class="accordion-content mt-4">
        <p class="mb-3 w-full md:text-lg">
          Prin implementarea planului de afaceri UAN SOLUTIONS SRL a înființat
          unui restaurant in municipiul Alba Iulia. Restaurantul asigură
          servirea mesei de la mic dejun până la cină și, la cerere, mese
          festive sau cu alte ocazii.
        </p>
        <p class="mb-3 w-full md:text-lg">
          Meniul este unul variat, atât vegan cât și clasic, pentru toate
          gusturile. Se servesc aperitive, antreuri, supe și ciorbe, feluri
          principale și garnituri, salate proaspete și salate din murături,
          diverse sosuri și arome, precum și sortimente variate de băuturi
          răcoritoare, alcoolice, cafea, sucuri naturale etc.
        </p>
        <p class="mb-3 w-full md:text-lg">
          Pentru prepararea acestor meniuri se utilizează ingrediente locale în
          cea mai mare măsură proaspete și de calitate.
        </p>
        <p class="mb-3 w-full md:text-lg">
          Meniul stabilit va fi actualizat permanent pentru a asigura elementul
          surpriză clienților de fiecare data când intră în restaurant.
        </p>
        <p class="mb-3 w-full md:text-lg">
          Pentru mai multe informații despre serviciile oferite sau pentru
          oferte personalizate, apelează 0753320306.
        </p>
        <p class="mb-3 w-full md:text-lg">
          Le urăm mult succes în implementarea planului de afaceri!
        </p>
      </div>
    </>
  );
}
