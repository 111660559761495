export const options = {
  title: "The Royal event for you – dă stil evenimentelor tale",
  category: "AFACERI",
  category_link: "/projectsbusiness",
  id: 76,
};

export default function Post() {
  return (
    <>
      <div class="accordion-content mt-4">
        <p class="mb-3 w-full md:text-lg">
          Nunta, botezul și zilele de naștere reprezintă unele dintre cele mai
          importante evenimente din viața unei persoane, iar THE ROYALS EVENTS
          își propune să transforme orice eveniment obișnuit într-unul
          spectaculos, de calitate și bun gust și de a mulțumi clienții prin
          serviciile oferite.
        </p>
        <p class="mb-3 w-full md:text-lg">
          Apelând la the Royal Events, clienții se pot bucura liniștiți de o
          petrecere încântătoare, plină de elemente moderne, unice și de bun
          gust. Firma închiriază produse „de efect” , precum cort de evenimente,
          balansoare romantice decorate cu flori artificiale de înaltă calitate,
          tavane false cu flori curgătoare artificiale și panouri înflorate ce
          creează o atmosfera feerica, coloane ce vor da un aer boem
          evenimentului, scaune decorate la cerere, precum și alte creații
          proprii adaptate în funcție de cererea clientului.
        </p>
        <p class="mb-3 w-full md:text-lg">
          Oana Forest SRL reprezintă o baza de producție pentru fabricarea
          produselor din lemn destinate utilării construcțiilor și amenajării
          locuințelor:
        </p>
        <p class="mb-3 w-full md:text-lg">
          Firma oferă ca servicii principale: acces în corturi de evenimente,
          organizarea în interiorul sediului firmei de evenimente mai restrânse,
          închirieri mese și scaune evenimente, elemente decorative, producție
          proprie, create sau decorate pentru evenimente precum nunti, botezuri,
          petreceri private, aniversari, petreceri cu tematica, cocktail-uri,
          evenimente corporate etc.
        </p>
        <p class="mb-3 w-full md:text-lg">
          Firma are sediul în SAT FILEA, COM DEDA, JUD MURES, 173. Pentru mai
          multe informații despre serviciile oferite sau pentru oferte
          personalizate, apelați 0730202021.
        </p>
        <p class="mb-3 w-full md:text-lg">
          Le urăm mult succes în implementarea planului de afaceri!
        </p>
      </div>
    </>
  );
}
