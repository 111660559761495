export const options = {
  title: "Cursul de Patiser continuă",
  category: "PROGRES",
  category_link: "/projectsprogress",
  id: 67,
};

export default function Post() {
  return (
    <>
      <div class="accordion-content mt-4">
        <p class="mb-3 w-full md:text-lg">
          Prima grupă de cursanți în domeniul Patiser a susținut examenul de
          calificare în data de 6 iulie 2023. Pe aceasta cale dorim să felicităm
          toți participanții care au promovat examenul și au obținut calificarea
          mult dorită.
        </p>
        <p class="mb-3 w-full md:text-lg">
          A doua grupă continuă activitatea de curs. Ei vor au finalizat partea
          teoretică, urmând să înceapă partea practică la operatori economici
          care activează în acest domeniu.
        </p>
        <p class="mb-3 w-full md:text-lg">
          Cu bucurie anunțăm că locurile în grupa a treia de curs au fost
          ocupate, iar cursul va începe la finalul lunii iulie.
        </p>
        <p class="mb-3 w-full md:text-lg">
          Cursul de patiser se desfășoară în cadrul proiectului ”Creșterea
          ocupării, precum și îmbunătățirea nivelului de competențe al tinerilor
          NEETs”, iar participarea este gratuită pentru membri grupului țintă.
        </p>
      </div>
    </>
  );
}
