export const options = {
  title: "Cursul de gestiunea deșeurilor are locuri disponibile",
  category: "PROGRES",
  category_link: "/projectsprogress",
  id: 75,
};

export default function Post() {
  return (
    <>
      <div class="accordion-content mt-4">
        <p class="mb-3 w-full md:text-lg">
          A treia grupă de curs în domeniul gestiunii deșeurilor a început
          activitatea. Cursul se va finaliza la finalul lunii, urmând ca
          participanții să susțină examenul în data de 31 august.
        </p>
        <p class="mb-3 w-full md:text-lg">
          De asemenea, continuă recrutările pentru grupa a patra. Cursul de
          gestiunea deșeurilor este organizat în cadrul proiectului ”Creșterea
          ocupării, precum și îmbunătățirea nivelului de competențe al tinerilor
          NEETs”. Acesta se desfășoară online, iar participanții primesc câte o
          tabletă pentru a putea accesa cursul.
        </p>
        <p class="mb-3 w-full md:text-lg">
          Dacă ești interesat de acest curs, contactează-ne!
        </p>
      </div>
    </>
  );
}
