export const options = {
  title: "Cursul de gestiunea deșeurilor are locuri disponibile",
  category: "PROGRES",
  category_link: "/projectsprogress",
  id: 68,
};

export default function Post() {
  return (
    <>
      <div class="accordion-content mt-4">
        <p class="mb-3 w-full md:text-lg">
          Până la momentul actual, 43 de membri ai grupului țintă au obținut
          diploma de absolvire a cursului de gestiune a deșeurilor organizat în
          cadrul proiectului ”Creșterea ocupării, precum și îmbunătățirea
          nivelului de competențe al tinerilor NEETs”. Acesta s-a desfășurat
          online, iar participanții au primit câte o tabletă pentru a putea
          accesa cursul.
        </p>
        <p class="mb-3 w-full md:text-lg">
          Următoarea grupă va începe în luna august, dacă îți dorești și tu să
          participi, contactează-ne!
        </p>
      </div>
    </>
  );
}
