export default function LabelSvg() {
	return (
		<svg
                      className='w-6 h-6 text-gray-800 dark:text-white'
                      aria-hidden='true'
                      xmlns='http://www.w3.org/2000/svg'
                      fill='#fbbf24'
                      viewBox='0 0 14 20'
                    >
                      <path d='M13 20a1 1 0 0 1-.64-.231L7 15.3l-5.36 4.469A1 1 0 0 1 0 19V2a2 2 0 0 1 2-2h10a2 2 0 0 1 2 2v17a1 1 0 0 1-1 1Z' />
                    </svg>
	)
}