export const options = {
  title: "Recrutările pentru grupul țintă continuă în luna iulie 2023",
  category: "PROGRES",
  category_link: "/projectsprogress",
  id: 65,
};

export default function Post() {
  return (
    <>
      <div class="accordion-content mt-4">
        <p class="mb-3 w-full md:text-lg">
          Cu bucurie vă anunțăm că încă mai aveți șansa să profitați de
          oportunitățile oferite de proiectul ”Creșterea ocupării, precum și
          îmbunătățirea nivelului de competențe al tinerilor NEETs”. În cadrul
          proiectului puteți obține calificarea de pavator, iar apoi noi vă vom
          sprijini în găsirea unui loc de muncă.
        </p>
        <p class="mb-3 w-full md:text-lg">
          De asemenea, puteți opta pentru cursul de specializare în domeniul
          gestiunii deșeurilor.
        </p>
        <p class="mb-3 w-full md:text-lg">
          Activitățile proiectului se adresează tinerilor NEETs din regiunea
          centru cu domiciliul în Alba, Brașov, Covasna, Harghita, Mureș sau
          Sibiu și cu vârsta între 16 și 29 de ani. Toate cursurile se
          desfășoară online, iar participanții vor primi câte o tabletă. Contact
          – 0739.162.605
        </p>
      </div>
    </>
  );
}
